/**
 * Entrypoint für rollup
 *
 * Dokumentation und Hilfe zu rollup unter rollupjs.org
 *
 * Hinweis: Bei der Verwendung von ES2015 Features wie
 * promises, symbols, collections, iterators, typed arrays etc.
 * muss `core-js` importiert werden!
 *
 * Dokumentation dazu unter https://github.com/zloirock/core-js
 *
 * Wenn man zum Beispiel nur Promises verwenden will muss folgender Code am Anfang dieser Datei stehen:
 *
 *          import 'core-js/fn/promise'
 *
 */
import 'core-js/fn/object/assign'

import domready from 'domready'
import mobileNavigation from './modules/mobile-navigation'
import initToggleSubMenu from './modules/toggle-sub-menu'
import Swiper from 'swiper'
import baguetteBox from 'baguettebox.js'
import formSubmit from './modules/form-submit'
import initMaps from './modules/map'

/**
 * Ab geht die Reise
 */
domready(() => {
  initMaps()
  // eslint-disable-next-line
  new Swiper('.swiper-container', {
    slidesPerView: 1,
    paginationClickable: true,
    spaceBetween: 0,
    speed: 2000,
    autoplay: 7000,
    effect: 'fade'
  })
  baguetteBox.run('.lightbox')
  initToggleSubMenu()
  formSubmit()
  mobileNavigation({
    activationElement: '.mobile-hamburger',
    breakpoint: 768
  })
})
